.cart {
    padding: 10px;
}
.cart__item {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 5px;
}
.cart__title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
}
.cart__price--new {
    color: green;
    font-size: 20px;
    font-weight: 700;
}
.cart__price--old {
    color: red;
    font-size: 16px;
    font-weight: 700;
}
.cart__image {
    width: 200px;
    aspect-ratio: 4/3;

}
.cart__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.cart__infor {
    flex: 1;
    padding: 5px;

}
.cart__meta {
    margin-right: 10px;
    display: flex;
}
.quanity {
    font-weight: 700;
    font-size: 20px;
    margin-right: 10px;
}
.cart__meta > button {
    width: 50px;
    height: 30px;
}
.cart__total {
   text-align: end;
   margin-right: 30px;
}
.delete__all{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    padding: 0 50px;
}